#collageContainer {
    height: auto;
    width: auto;
    margin: 10px;
}

#collageImage {
    height: auto;
    width: auto;
    max-width: 250px;
}

