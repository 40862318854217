@media only screen and (min-width: 1440px) {

    .contactSection {
        width: 565px;
        margin: 0px 50px;
        background-color: white;
        border: 3.5px solid #3c403f;
        border-radius: 30px;
        padding: 20px;
    }

    #contactContent {
        padding: 150px 0px 100px 0px;
        max-width: 1500px;
    }

    #sendButton {
        border: 2px solid gray;
        margin-top: 50px;
        background-color: #a2b8a0;
        color: rgb(82, 82, 82);
    }

}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {

    .contactSection {
        width: 565px;
        margin: 40px 50px;
        background-color: white;
        border: 2px solid #3c403f;
        border-radius: 30px;
        padding: 20px;
    }

    #contactContent {
        padding: 110px 75px;
        max-width: 1500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #contactSectionTitle {
        font-family: 'Poppins', sans-serif;
        font-size: 2.5em;
    }

    #sendButton {
        margin-top: 40px;
    }
    
}

@media only screen and (max-width: 1024px) {

    .contactSection {
        width: auto;
        max-width: 400px;
        background-color: white;
        border: 2px solid #3c403f;
        border-radius: 30px;
        margin: 20px;    
        padding: 20px;
    }

    #contactContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #contactSectionTitle {
        font-size: 2.3em;
    }

    #sendButton {
        margin-top: 40px;
    }

}