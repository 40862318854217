.devbg {
  background-color: red;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    max-width: 2000px;
    background: url('/src/watercolor.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

h1 {
  font-size: 5.2em;
  font-family: 'Hubballi', cursive;
  font-weight: 400;
  color: #3c403f;
  margin: 0;
}

h4 {
  font-size: 1.7em;
  font-family: 'Hubballi', cursive;
  font-weight: 600;
  color: #3c403f;
  margin: 0;
}

img {
  border-radius: 20px;
}

.titleFont{
    font-family: 'Abril Fatface', cursive;
    font-weight: 200;
}

.hubballi400 {
    font-family: 'Hubballi', cursive;
    font-weight: 400;
}

.handlee400 {
    font-family: 'Handlee', cursive;
    font-weight: 400;
}

.amatic400 {
    font-family: 'Amatic SC', cursive;
    font-weight: 400;
}

.amatic700 {
    font-family: 'Amatic SC', cursive;
    font-weight: 700;
}

.poppins200 {
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
}

.poppins400 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.fontColorBlack {
    color: #3c403f;
}

.fontColorGreen {
    color: #a2b8a0;
}

.fontColorTan {
    color: #f9dab3;
}

.textDecorationNone {
    text-decoration: none;
}

.flexRowBetweenCenter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.flexRowBetweenStart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.flexRowAroundStart {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.flexRowAroundCenter {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.flexRowCenterCenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flexRowCenterStart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.flexRowRightCenter {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.flexRowStartCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.flexColumnCenterStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.flexColumnCenterEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.flexColumnCenterCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.width80 {
  width: 80%;
}

.width60 {
  width: 60%;
}

.width50 {
    width: 50%;
}

.borderLeft {
  border-left: 2px solid #a2b8a0;
  padding-left: 8px;
}

.borderRight {
  border-right: 2px solid #a2b8a0;
  padding-right: 8px;
}

.detailsButton {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 210px;
  background-color: #a2b8a0;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 2px 2px 5px black;
}

.divider {
  height: 1px;
  width: 90%;
  background-color: #3c403f;
}