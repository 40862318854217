@media only screen and (min-width: 1440px) {
    .Services {
        padding-top: 125px;
        padding-bottom: 100px;
        height: auto;
        background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('/src/5.jpg');
        background-repeat: no-repeat;
        background-size: cover;  
        display: flex;
        flex-direction: row;
        justify-content: flex-start ;
    }

    .Services > h4 {
        font-size: 2.5em;
    }

    #servicesLeft {
        width: 60%;
        margin-top: 60px;
    }
    
    .servicesTitle {
        font-size: 2.5em;
        width: 70%;
        font-weight: 700;
        color: #3c403f;
        background-color: white;
        border: 3.5px solid #3c403f;
        border-radius: 15px;
        padding: 20px;
        margin-bottom: 40px;
    }

    #servicesPhoto1 {
        width: 70%;
    }
    
    .stepsContainer {
        height: 400px;
        width: 500px;
        background-color: white;
        border: 3.5px solid #3c403f;
        border-radius: 15px;
        margin-top: 60px;
        margin-right:60px;
        padding: 15px 0px;
    }

    #stepsTitle {
        font-size: 2.5em;
        color: #3c403f;
    }

    .step {
        width: 90%;
        height: auto;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .midStep {
        border-bottom: 2px solid #3c403f;
    }

    .stepIcon {
        height: 60px;
        padding-right: 20px;
        padding-left:20px;
    }

    #phoneIcon {
        margin-right: 25px;
    }
      
    .stepTitle {
    font-size: 1.8rem;
    color: #3c403f;
    }
    
    .stepDescription {
    font-size: 1.1em;
    color: #3c403f;
    }

    #video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .backgroundVideo {
        height: 650px;
        width: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    }

    .videoOverlay {
        font-size: 3.5em;
    }
    
    .servicesContent {
        padding: 20px;
        background-color: white;
    }

    #servicesPhoto {
        width: 400px;
    }

    .servicesList {
        height: auto;
        width: 400px;
        padding-right: 0px;
        border: 3px solid #3c403f;
        border-radius: 40px;
        background-color: white;
        margin: 40px;
    }

    #servicesText {
        width: 80%;
    }

}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    .Services {
        padding-top: 125px;
        padding-bottom: 100px;
        height: auto;
        background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('/src/5.jpg');
        background-repeat: no-repeat;
        background-size: cover;  
        display: flex;
        flex-direction: row;
        justify-content: flex-start ;
    }

    .Services > h4 {
        font-size: 2.5em;
    }

    #servicesLeft {
        width: 60%;
        margin-top: 60px;
    }
    
    .servicesTitle {
        font-size: 2.5em;
        width: 70%;
        font-weight: 700;
        color: #3c403f;
        background-color: white;
        border: 3.5px solid #3c403f;
        border-radius: 15px;
        padding: 20px;
        margin-bottom: 40px;
    }

    #servicesPhoto1 {
        width: 70%;
    }
    
    .stepsContainer {
        height: 400px;
        width: 500px;
        background-color: white;
        border: 3.5px solid #3c403f;
        border-radius: 15px;
        margin-top: 60px;
        margin-right:60px;
        padding: 15px 0px;
    }

    #stepsTitle {
        font-size: 2.5em;
        color: #3c403f;
    }

    .step {
        width: 90%;
        height: auto;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .midStep {
        border-bottom: 2px solid #3c403f;
    }

    .stepIcon {
        height: 60px;
        padding-right: 20px;
        padding-left:20px;
    }

    #phoneIcon {
        margin-right: 25px;
    }
      
    .stepTitle {
    font-size: 1.8rem;
    color: #3c403f;
    }
    
    .stepDescription {
    font-size: 1.1em;
    color: #3c403f;
    }

    #video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .backgroundVideo {
        height: 650px;
        width: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    }

    .videoOverlay {
        font-size: 2.5em;
    }
    
    .servicesContent {
        padding: 20px;
        background-color: white;
    }

    #servicesPhoto {
        width: 400px;
    }

    .servicesList {
        height: auto;
        width: 400px;
        padding-right: 0px;
        border: 3px solid #3c403f;
        border-radius: 40px;
        background-color: white;
        margin: 40px;
    }

    #servicesText {
        width: 80%;
    }
}

@media only screen and (max-width: 1024px) {
    .Services {
        height: auto;
        padding: 30px 20px;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), url('/src/5.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    .servicesTitle {
        font-size: 1.8em;
        font-weight: 700;
        margin-bottom: 40px;
    }
    
    .stepsContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .step {
        width: 80%;
        height: 180px;
        margin: 4px 0px;
        background-color: white;
        border: 2.5px solid #3c403f;
        border-radius: 15px;
        padding: 25px;
        display: flex;
      }

      .stepIcon {
          height: 60px; 
      }
      
      .step > h1 {
        font-size: 1.5rem;
        font-weight: 700;
      }
      
      .step > h4 {
        font-size: 1.1em;
      }

      #video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .backgroundVideo {
        height: 450px;
        width: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    }
    
      .servicesContent {
        padding: 10px;
        background-color: white;
    }

    .servicesList {
        height: auto;
        width: 90%;
        border: 4px solid #3c403f;
        border-radius: 40px;
        background-color: white;
        margin: 10px;
    }

    #servicesText {
        width: 80%;
    }
}