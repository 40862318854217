form {
    width: 100%;
    margin: 20px;
}

#contactSectionTitle {
    font-family: 'Satisfy', cursive;
    font-weight: 200;
    font-size: 2.5em;
    color: #3c403f;
}

.contactFormLabel {
    color: #3c403f;
}

input {
    width: 100%;
    height: 40px;
    font-size: 1.2em;
    font-family: 'Poppins', sans-serif;
    color: #3c403f;
    display: inline-block;
    border: none;
    border-bottom: 2px solid #3c403f;
    box-sizing: border-box;
    /* background-color: whitesmoke; */
}

input:focus {
    outline: none;
}

textarea {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0px;
    display: inline-block;
    border: 3px solid #3c403f;
    border-radius: 14px;
    box-sizing: border-box;
    box-sizing: border-box;
    background-color: whitesmoke;
    box-sizing: border-box;
    height: 100px;
    width: 100%;
    max-width: 100%;
}
  
  
button[type=submit] {
    text-decoration: none;
    width: 100%;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 1.2em;
    font-family: 'Poppins', sans-serif;
}

button[type=submit]:hover {
    background-color: #45a04993;
}

#sendButton {
    color: #3c403f;
    border: 2px solid #3c403f;
    border-radius: 15px;
}

.marginTop40 {
    margin-top: 40px;
}