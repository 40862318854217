@media only screen and (min-width: 1440px) {

    .header {
        position: fixed;
        background-color: transparent;
        height: 125px;
        width: 100%;
        color: #a2b8a0;
        background-color: rgb(0,0,0,0.4);
        z-index: 5;
    }

    @-webkit-keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }

    .headerTransparent {
        background-image: linear-gradient(to right, rgb(0,0,0,0.8) , rgb(0,0,0,0.4));
    }

    .headerSolid {
        background-color: #a2b8a0;
        -webkit-animation: fadeIn 1s;
        animation: fadeIn 1s;
    }

    .icon {
        height: 112px;
        position: fixed;
        top: 10px;
        left: 10px;
    }

    .logoText {
        font-family: 'Satisfy', cursive;
        letter-spacing: .1em;
        line-height: 1.2em;
        font-weight: 400;
        padding-top: 2px;
    }

    .headerLeft {
        margin: 0px 115px;
        position: fixed;
        top: 0;
        left: 40px;
    }

    .titleContainer {
        margin-top: 1em;
    }

    .titleContainer > h1 {
        font-size: 2em;
    }

    .navLink {
        font-family: 'Poppins', sans-serif;
        font-size: 1.5em;
        font-weight: 200;
        margin-right: 65px;
    }

}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {

    .header {
        position: fixed;
        background-color: transparent;
        height: 125px;
        width: 100%;
        color: #a2b8a0;
        background-color: rgb(0,0,0,0.4);
    }

    @-webkit-keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }

    .headerTransparent {
        background-color: rgb(0,0,0,0.4);
    }

    .headerSolid {
        background-color: #a2b8a0;
        -webkit-animation: fadeIn 1s;
        animation: fadeIn 1s;
    }

    .icon {
        height: 112px;
        position: fixed;
        top: 10px;
        left: 10px;
    }

    .logoText {
        font-family: 'Satisfy', cursive;
        letter-spacing: .1em;
        line-height: 1.2em;
        font-weight: 400;
        padding-top: 2px;
    }

    .headerLeft {
        margin: 0px 115px;
        position: fixed;
        top: 0;
        left: 30px;
    }

    .titleContainer {
        margin-top: 1em;
    }

    .titleContainer > h1 {
        font-size: 2em;
    }

    .navLink {
        font-family: 'Poppins', sans-serif;
        font-size: 1.5em;
        font-weight: 200;
        margin-right: 65px;
    }

}

@media only screen and (max-width: 1024px) {
    .header {
        background-color: #a2b8a0;
        height: 50px;
        width: 100%;
    }

    .headerLeft {
        margin-left: 0px;
    }

    .headerLeft > a > h1 {
        font-size: 1em;        
    }

    .icon {
        height: 50px;
    }

}