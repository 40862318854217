@media only screen and (min-width: 1440px) {

    .home {
    padding: 0px;
    }

    .landing {
        height: 725px;
        background-color: rgba(0, 0, 0, 0.75);      
    }

    .landingContent {
        width: 80%;
    }

    .landingContent > h4 {
        font-size: 2.3em;
    }

    #video {
        position: fixed;
        top: 0;
        z-index: -1;
        width: 100%;
    }

    .sec2Container {
        padding: 20px 40px;
    }

    .sec2 {
        margin-top: -175px;
        width: 90%;
        height: 300px;
        background: url('/src/interlaced.png');
        border-radius: 35px;
        padding: 0px 20px;
    }

    .sec23Container {
        background-color: white;
    }

    .sec3 {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 20px;
        height: auto;
    }

    .sec3Right {
        width: 800px;
        padding: 0px 10px;
    }

    .sec3Right > h1 {
        font-size: 2.3em;
        font-weight: 700;
    }

    .sec3Content > img {
        box-shadow: 1px 1px 10px black;
    }

    .sec3Content > h4 {
            font-size: 1.7em;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            color: #a2b8a0;
    }

    #homePhoto {
        width: auto;
        max-width: 400px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {

    .home {
    padding: 0px;
    }

    #homeTitle {
        font-size: 4.5em;
    }

    .landing {
        height: 725px;
        background-color: rgba(0, 0, 0, 0.75);      
    }

    .landingContent {
        width: 80%;
    }

    #video {
        position: fixed;
        top: 0;
        z-index: -1;
        width: 100%;
    }

    .sec2Container {
        padding: 20px 10px;
    }

    .sec2 {
        margin-top: -175px;
        width: 100%;
        height: 300px;
        background: url('/src/interlaced.png');
        border-radius: 35px;
        padding: 0px 20px;
    }

    .sec2 > h1 {
        width: 90%;
        font-size: 2.4em;
        font-family: 'Poppins', sans-serif;
        font-weight: 200;
        color: #3c403f;
    }
      

    .sec23Container {
        background-color: white;
    }

    .sec3 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 20px 10px;
        height: auto;
    }

    .sec3Right {
        width: 50%;
    }

    .sec3Right > h1 {
        font-size: 2.2em;
        font-weight: 700;
        margin: 0;
    }

    .sec3Right > h4 {
        font-size: 1.2em;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        margin: 0;
    }
    .sec3Left > img {
        box-shadow: 1px 1px 10px black;
        width: 400px;
    }

    #homePhoto {
        width: 90%;
        margin: 40px;
    }
}

@media only screen and (max-width: 1024px) {
    .home {
        padding: 0px;
        width: 100%;
    }

    #video {
        position: fixed;
        top: 0;
        z-index: -1;
        max-height: 725px;
    }

    .landing {
        height: 655px;
        background-color: rgba(0, 0, 0, 0.77);
    }

    .sec2Container {
        height: auto;
        padding: 5px 5px;
        background-color: white;
    }
    
    .sec2 {
        min-width: 90%;
        height: 975px;
        background: url('/src/interlaced.png');
        border-radius: 35px;
    }

    .sec3 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 20px 10px;
        height: auto;
        background-image: url('/src/5.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .sec3Left {
        width: 100%;
    }

    .sec3Left > h1 {
        font-size: 2.2rem;
        font-family: 'Satisfy', cursive;
        font-weight: 200;
        color: #a2b8a0;
        margin: 0;
    }

    .sec3Right > h1 {
            font-size: 3.1rem;
            font-weight: 600;
            margin: 0;
    }

    .sec3Right {
        width: 100%;
        padding: 20px 0px;
    }

    #homePhoto{
        width: 95%;
    }
    
}