@media only screen and (min-width: 1440px) {

    .About {
        height: auto;
        margin: 0;
        padding-top: 200px;
        padding-bottom: 100px;
        background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url('/src/5.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .aboutContent {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .aboutContent > h4 {
        max-width: 70%;
        text-align: left;
    }

    .aboutContent > img {
        max-width: 20%;
        margin: 45px;
    }

    .aboutSec2 {
        width: auto;
        height: auto;
        padding: 0px;
    }

    .quote {
        height: auto;
        width: 600px;
        padding: 25px;
        margin: 40px;
        border: 3px solid #3c403f;
        border-radius: 40px;
        background-color: white;
    }

    .quote > h1 {
        font-size: 2em;
        text-align: left;
    }

    .quote > h4 {
        margin-top: 15px;
        font-size: 1.2em;
        text-align: right;
    }

    #aboutPhotoLeft {
        max-width: 650px;
    }

    #aboutPhoto {
        max-width: 600px;
    }

}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {

    #angelinaPortrait {
        width: 100%;
    }
    .About {
        height: auto;
        margin: 0;
        padding-top: 200px;
        padding-bottom: 100px;
        background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url('/src/5.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .aboutContent {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .aboutContent > h4 {
        max-width: 70%;
        text-align: left;
    }

    .aboutContent > img {
        max-width: 20%;
        margin: 45px;
    }

    .aboutSec2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin: 75px 0px;
    }

    .quote {
        height: auto;
        width: 70%;
        padding: 25px;
        margin-bottom: 50px;
        border: 3px solid #a2b8a0;
        border-radius: 40px;
        background-color: white;
    }

    .quote > h1 {
        font-size: 2em;
        text-align: left;
        color: #a2b8a0;
    }

    .quote > h4 {
        margin-top: 15px;
        font-size: 1.2em;
        text-align: right;
        color: #a2b8a0;
    }

    #aboutPhoto {
        height: auto;
        width: 650px;
    }
    
    #aboutPhotoLeft {
        display: none;
    }

}

@media only screen and (max-width: 1024px) {

    #aboutH1 {
        font-size: 2.5em;
    }

    #angelinaPortrait {
        width: 300px;
        margin: 45px 40px;
    }

    .About {
        height: auto;
        margin: 0;
        padding-top: 20px;
        padding-bottom: 100px;
        background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url('/src/5.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .aboutText1 {
        width: 90%;
        padding-left: 20px;
        border-left: 1px solid #a2b8a0;
    }

    .aboutSec2 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 90%;
        margin: 75px 0px;
    }

    .aboutText2 {
        font-size: 2.5em;
    }

    .quote {
        height: auto;
        width: 80%;
        padding: 25px;
        border: 3px solid #a2b8a0;
        border-radius: 40px;
        background-color: white;
    }

    #aboutImage {
        width: 90%;
    }

    #aboutPhoto {
        height: auto;
        max-width: 300px;
    }

    #aboutPhotoLeft {
        width: 90%;
        margin-bottom: 40px;
    }

}