@media only screen and (min-width: 1440px) {

    #Gallery {
        height: auto;
        padding-top: 175px;
        z-index: 0;
    }

    #photoCarousel {
        width: 30%;
        padding: 20px;
        border: 1px solid #3c403f;
        border-radius: 15px;
    }

    #collage {
        height: auto;
        width: auto;
        flex-wrap: wrap;
        margin-left: 45px;
        border: 1.5px solid #3c403f;
        border-radius: 25px;
        background-color: #a2b8a08a;
    }

    #testimonialContainer {
        margin: 25px;
        max-width: 30%;
        width: auto;
    }

    .testimonial {
        height: auto;
        background-color: white;
        border: 1px solid #3c403f;
        border-radius: 20px;
        margin-bottom: 50px;
    }

    .testimonialText{
        font-size: 1.2em;
        padding: 10px 15px;
    }

}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    #Gallery {
        height: auto;
        padding-top: 175px;
    }

    #collage {
        height: auto;
        width: auto;
        flex-wrap: wrap;
        margin-left: 45px;
        border: 1.5px solid #3c403f;
        border-radius: 25px;
        background-color: #a2b8a08a;
    }

    #testimonialContainer {
        margin: 15px;
    }

    .testimonial {
        height: auto;
        width: 500px;
        background-color: white;
        border: 1px solid #3c403f;
        border-radius: 20px;
        margin-bottom: 50px;
    }

    .testimonialText{
        font-size: 1.2em;
        padding: 10px 15px;
    }
}

@media only screen and (max-width: 1024px) {
    #Gallery {
        height: auto;
        padding-top: 10px;
    }

    #collage {
        height: auto;
        width: auto;
        flex-wrap: wrap;
    }

    #testimonialContainer {
        margin: 15px;
    }

    .testimonial {
        height: auto;
        width: auto;
        background-color: white;
        border: 1px solid #3c403f;
        border-radius: 20px;
        margin-bottom: 5px;
    }

    .testimonialText{
        font-size: 1.2em;
        padding: 10px 15px;
    }
}