@media only screen and (min-width: 1024px) {
    
    .footer {
        background: #3c403f;
        border-top: 1px solid #a2b8a0;
        padding: 20px 0px;
    }

    .footH1 {
        font-size: 3em;
    }

    .footH4 {
        font-size: 1.2em;
    }

    .footH4 {
        font-size: 1.2em;
        font-weight: 400;
        color: #a2b8a0
    }

    .footerCopyright {
        padding-top: 10px;
    }

    .footerLeft {
        margin-left: 75px;
    }

    .footerRight {
        margin-right: 75px;
    }

    #heartIcon {
        height: 1em;
    }

    .divider {
        height: 2px;
        width: 90%;
        background-color: #a2b8a0;
        margin-top: 15px;
        margin-bottom: 15px;
    }

}

@media only screen and (max-width: 1024px) {

    .footer {
        background-color: #a2b8a0;
    }

    .footIcon {
        height: 160px;
        border-radius: 0px;
        margin: 0px 10px;
    }

    .footH1 {
        font-size: 1.5em;
        font-weight: 400;
        color: #3c403f;
    }

    .footH4 {
        font-size: 18px;
        font-weight: 400;
        color: #3c403f;
    }

    .footerRight {
        margin-top: 10px;
        padding-bottom: 10px;
    }

    .footH4Right {
        font-size: 0.8em;
        font-weight: 400;
        color: #3c403f
    }

}